import React from "react";
import compare_strings from "./fuzzy.js";

const recenice = [
  "Poz<b>dra</b>v",
  "Dobar dan",
  "Zdravo",
  "Poz<b>dra</b>v",
  "Dobar dan",
  "Zdravo",
  "Poz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>v",
  "Dobar dan",
  "Poz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>vPoz<b>dra</b>v",
  "Zdravo",
  "Poz<b>dra</b>v",
  "Dobar dan",
  "Zdravo",
  "Poz<b>dra</b>v",
  "Dobar dan",
  "Zdravo",
  "Poz<b>dra</b>v",
  "Dobar dan",
  "Zdravo",
  "Poz<b>dra</b>v",
  "Dobar dan",
  "Zdravo",
  "Poz<b>dra</b>v",
  "Dobar dan",
  "Zdravo",
]

function scoreColor(score) {
  if (score < 0.5) {
    return "border-red-400";
  } else if (score < 0.8) {
    return "border-orange-400";
  } else {
    return "border-green-400";
  }
}

console.log(compare_strings("Pozdrav", recenice));

function App() {
  const [searchQuery, setSearchQuery] = React.useState("");
  return (
    <div className="App">
      {/* <p className="font-sans text-5xl mt-5 text-center">{searchQuery}</p> */}
      <div className="overflow-y-scroll">
        {
          compare_strings(searchQuery, recenice).slice(0, 5).map((item, index) => {
            // return <p key={index}>{item.score} - {item.str}</p>
            return <div className={"border rounded-md m-2 p-2 break-words " + scoreColor(item.score)} key={index}>{<span dangerouslySetInnerHTML={{ __html: item.str }} />}</div>
          })
        }
      </div>
      <hr className="mt-1 mb-20" />
      <div className="flex place-content-center">
        <input
          className="border-2 border-gray-200 rounded-md place-content-center fixed bottom-5 w-11/12 p-3 shadow-xl"
          onChange={(x) => { setSearchQuery(x.target.value) }}
          placeholder="Pretrazi"
        />
      </div>
    </div>
  );
}

export default App;
